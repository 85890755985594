import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../mystyles.scss"
import Typewriter from 'typewriter-effect';

const Landing = ({ location }) => {

    return (
        <Layout location={location} >
                <SEO title="Home" />

                <div className="container">

                    <div className="columns is-centered">

                        <div className="m-5">

                            <div className="title is-family-monospace"  style={{minHeight:"200px"}}>
                                <Typewriter
                                    options={{
                                        strings: ['Copywriter', 'Idea scribbler', 'Enthusiastic dancer', 'Word conjurer', 'Concept creator ', 'Tea drinker', 'Blank-page filler', 'Brief ponderer', 'Nature wanderer'],
                                        autoStart: true,
                                        loop: true,
                                        delay: 50,
                                        deleteSpeed: 50
                                    }}
                                />
                            </div>

                        </div>

                    </div>
                </div>

        </Layout>

    )
}

export default Landing